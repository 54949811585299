export const cloudTypeData = [
  // { label: "Amazon Q Developer", value: "Amazon Q Developer" },
  { label: "Amazon Q Business", value: "Amazon Q Business" },
  { label: "AWS Bedrock", value: "AWS Bedrock" },
  { label: "Azure OpenAI", value: "Azure OpenAI" },
];

export const scopeOfUsers = [
  {
    label: "Internal users only (e.g., employees, departments)",
    value: "Internal users only (e.g., employees, departments)",
  },
  {
    label:
      "External users only (e.g., customers, vendors, third-party partners)",
    value:
      "External users only (e.g., customers, vendors, third-party partners)",
  },
  {
    label: "Both internal and external users",
    value: "Both internal and external users",
  },
];
export const typeOfTasks = [
  { label: "IT support and service", value: "IT support and service" },
  { label: "Sales and marketing", value: "Sales and marketing" },
  {
    label: "Data analysis and reporting",
    value: "Data analysis and reporting",
  },
  { label: "Supply chain management", value: "Supply chain management" },
  { label: "HR functions", value: "HR functions" },
  { label: "Others (please specify)", value: "Others (please specify)" },
];
export const typeOfDataProcessed = [
  {
    label:
      "Personal Identifiable Information (PII) – Names, addresses, contact info, etc.",
    value:
      "Personal Identifiable Information (PII) – Names, addresses, contact info, etc.",
  },
  {
    label: "Sensitive Personal Data (SPD) – Health data, biometric data, etc.",
    value: "Sensitive Personal Data (SPD) – Health data, biometric data, etc.",
  },
  {
    label: "Financial Data – Credit card info, transaction history, etc.",
    value: "Financial Data – Credit card info, transaction history, etc.",
  },
  {
    label:
      "Confidential Business Data – Internal documents, proprietary processes, etc.",
    value:
      "Confidential Business Data – Internal documents, proprietary processes, etc.",
  },
  {
    label:
      "Intellectual Property (IP) – Patents, trade secrets, source code, etc.",
    value:
      "Intellectual Property (IP) – Patents, trade secrets, source code, etc.",
  },
  {
    label:
      "Secret or Classified Information – Governmental or defense-related data",
    value:
      "Secret or Classified Information – Governmental or defense-related data",
  },
  {
    label: "Non-Personal Data – Operational data, analytics, etc.",
    value: "Non-Personal Data – Operational data, analytics, etc.",
  },
];
export const dataTypeOptions = [
  {
    label:
      "Dummy Data: Random data used as a placeholder for testing purposes.",
    value:
      "Dummy Data: Random data used as a placeholder for testing purposes.",
  },
  {
    label:
      "Synthetic Data: Artificially generated data that mimics real data structure for building generative AI applications.",
    value:
      "Synthetic Data: Artificially generated data that mimics real data structure for building generative AI applications.",
  },
  { label: "Development Data", value: "Development Data" },
  { label: "Production Data", value: "Production Data" },
];

export const programmingLanguages = [
  { label: "Python", value: "Python" },
  { label: "Java", value: "Java" },
  { label: ".Net", value: ".Net" },
  { label: "C", value: "C" },
  { label: "C#", value: "C#" },
  { label: "PHP", value: "PHP" },
];

export const regionOption = [
  { label: "Global", value: "Global" },
  { label: "SNA", value: "SNA" },
  { label: "SOLA", value: "SOLA" },
  { label: "EU", value: "EU" },
  { label: "AP", value: "AP" },
];
export const aiUsageTypes = [
  { label: "Standard", value: "Standard" },
  { label: "Internal Only", value: "Internal Only" },
  { label: "Restricted", value: "Restricted" },
  { label: "Prohibited", value: "Prohibited" },
];
export const applicationTypes = [
  { label: "Chatbot, or Productivity", value: "Chatbot, or Productivity" },
  {
    label: "Images (generation, editing, enhancement, etc.)",
    value: "Images (generation, editing, enhancement, etc.)",
  },
  {
    label: "Audio/Video (generation, editing, enhancement, etc.)",
    value: "Audio/Video (generation, editing, enhancement, etc.)",
  },
  {
    label: "Metadata (tagging, extraction, generation, etc.)",
    value: "Metadata (tagging, extraction, generation, etc.)",
  },
  { label: "Analytics or Data Science", value: "Analytics or Data Science" },
  { label: "Marketing", value: "Marketing" },
  { label: "Others (please specify)", value: "Others (please specify)" },
];
export const toolsDistribution = [
  {
    label: "Internal-only process or workflow",
    value: "Internal-only process or workflow",
  },
  { label: "Marketing, promo, socials", value: "Marketing, promo, socials" },
  { label: "Commercial release", value: "Commercial release" },
];
export const businessJustifications = [
  { label: "Curiosity/Testing", value: "Curiosity/Testing" },
  { label: "Revenue Generation", value: "Revenue Generation" },
  {
    label: "Productivity or Cost Savings",
    value: "Productivity or Cost Savings",
  },
  {
    label: "Systems Efficiency / Enhancement",
    value: "Systems Efficiency / Enhancement",
  },
];
export const typeOfEnvironments = [
  { label: "Playground (POC Purpose)", value: "Playground (POC Purpose)" },
  { label: "Development", value: "Development" },
  { label: "Production", value: "Production" },
];
export const qualityControlConsents = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "I’m not sure", value: "I’m not sure" },
  {
    label: "N/A - not relevant for this use case",
    value: "N/A - not relevant for this use case",
  },
];

export const categoryOfDataOption = [
  {
    label: "Sony Employee PI (e.g., Business Contact Information ONLY)",
    value: "Sony Employee PI (e.g., Business Contact Information ONLY)",
  },
  {
    label: "Sony Employee PI (e.g., NON-Business Contact Information)",
    value: "Sony Employee PI (e.g., NON-Business Contact Information)",
  },
  {
    label:
      "Sony Consumer PI (e.g., end-users who purchase Sony goods/services)",
    value:
      "Sony Consumer PI (e.g., end-users who purchase Sony goods/services)",
  },
  {
    label: "Sony Retailer PI (e.g., big-box retailers)",
    value: "Sony Retailer PI (e.g., big-box retailers)",
  },
];

export const distributedOutputToolsOption = [
  {
    label: "Internal-only process or workflow",
    value: "Internal-only process or workflow",
  },
  { label: "Marketing, promo, socials", value: "Marketing, promo, socials" },
  { label: "Commercial release", value: "Commercial release" },
];

export const bedrockModelTypes = [
  { label: "Titan", value: "Titan" },
  { label: "Anthropic", value: "Anthropic" },
];

export const azureModelTypes = [
  { label: "GPT-4", value: "GPT-4" },
  // { label: "gpt3.5", value: "gpt3.5" },
];

export const qCloudServieList = [
  { label: "Q Developer", value: "Q Developer" },
];

export const llmModelAws = [
  { label: "Cluade", value: "Cluade" },
  { label: "Titan", value: "Titan" },
];
export const llmModelAzure = [{ label: "ChatGPT4", value: "ChatGPT4" }];
export const llmModelGcp = [{ label: "Gemini", value: "Gemini" }];
export const staticbillingRegion = [
  { label: "AM", value: "AM" },
  { label: "AP", value: "AP" },
  { label: "CN", value: "CN" },
  { label: "EU", value: "EU" },
  { label: "JP", value: "JP" },
  { label: "IN", value: "IN" },
];
export const staticbillto = [
  { label: "Global IS (GISC)", value: "Global IS (GISC)" },
  { label: "GSID ", value: "GSID" },
  { label: "Other Teams", value: "Other Teams" },
];
export const trueFalseData = [
  { label: "No", value: false },
  { label: "Yes", value: true },
];
